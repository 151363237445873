exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-grid-js": () => import("./../../../src/pages/blog-grid.js" /* webpackChunkName: "component---src-pages-blog-grid-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-gallery-page-js": () => import("./../../../src/pages/gallery-page.js" /* webpackChunkName: "component---src-pages-gallery-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-two-js": () => import("./../../../src/pages/index-two.js" /* webpackChunkName: "component---src-pages-index-two-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-agent-details-index-jsx": () => import("./../../../src/templates/agent-details/index.jsx" /* webpackChunkName: "component---src-templates-agent-details-index-jsx" */),
  "component---src-templates-blog-details-index-jsx": () => import("./../../../src/templates/blog-details/index.jsx" /* webpackChunkName: "component---src-templates-blog-details-index-jsx" */),
  "component---src-templates-categories-post-index-jsx": () => import("./../../../src/templates/categories-post/index.jsx" /* webpackChunkName: "component---src-templates-categories-post-index-jsx" */),
  "component---src-templates-tag-post-index-jsx": () => import("./../../../src/templates/tag-post/index.jsx" /* webpackChunkName: "component---src-templates-tag-post-index-jsx" */)
}

